import React, { useState, useEffect } from 'react';
import { 
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  Card,
  CardContent,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Avatar,
  Badge,
  Chip,
  CircularProgress
} from '@mui/material';
import {
  TrendingUp,
  AttachMoney,
  People,
  LocalShipping,
  Notifications,
  Inventory,
  Assessment,
  Warning,
  ArrowUpward,
  ArrowDownward,
} from '@mui/icons-material';
import { 
  AreaChart, 
  Area, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  ResponsiveContainer,
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell
} from 'recharts';
import { db } from '../config/firebaseConfig';
import { collection, getDocs, query, where, orderBy, limit } from 'firebase/firestore';
import OrderStageManagement from './orders/OrderStageManagement';

// Custom hook for generating chart data
const useChartData = () => {
  const [revenueData, setRevenueData] = useState([]);
  const [orderStatusData, setOrderStatusData] = useState([]);

  useEffect(() => {
    const fetchChartData = async () => {
      try {
        const ordersCollection = collection(db, 'orders');
        const orderSnapshot = await getDocs(ordersCollection);
        const orders = orderSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        // Generate Revenue Data
        const monthlyRevenue = orders.reduce((acc, order) => {
          // Use orderDate which is a string in 'YYYY-MM-DD' format
          if (order.status === 'done' && order.orderDate) {
            const orderDate = new Date(order.orderDate);
            const month = orderDate.toLocaleString('default', { month: 'short' });
            const revenue = order.profit || order.firstPaycheck || 0;
            
            acc[month] = (acc[month] || 0) + revenue;
          }
          return acc;
        }, {});

        const revenueChartData = Object.entries(monthlyRevenue)
        .map(([name, revenue]) => ({ name, revenue }))
        .sort((a, b) => {
          const monthOrder = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
          return monthOrder.indexOf(a.name) - monthOrder.indexOf(b.name);
        });

      console.log('Revenue Chart Data:', revenueChartData);

        setRevenueData(revenueChartData);
        console.log(revenueChartData);

         // Generate Order Status Data
         const orderStatusCounts = orders.reduce((acc, order) => {
          acc[order.status] = (acc[order.status] || 0) + 1;
          return acc;
        }, {});

        const orderStatusChartData = Object.entries(orderStatusCounts)
          .map(([name, value]) => ({ name, value }));

        setOrderStatusData(orderStatusChartData);
      } catch (error) {
        console.error('Error fetching chart data:', error);
      }
    };

    fetchChartData();
  }, []);

  return { revenueData, orderStatusData };
};

const AdminDashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [stats, setStats] = useState({
    totalRevenue: 0,
    totalOrders: 0,
    totalCustomers: 0,
    pendingOrders: 0
  });
  const [recentOrders, setRecentOrders] = useState([]);
  const [lowStockItems, setLowStockItems] = useState([]);

  // Use the custom chart data hook
  const { revenueData, orderStatusData } = useChartData();

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28'];

  useEffect(() => {
    const fetchDashboardData = async () => {
      setIsLoading(true);
      try {
        // Fetch orders
        const ordersCollection = collection(db, 'orders');
        const orderSnapshot = await getDocs(ordersCollection);
        const orders = orderSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        // Calculate stats
        const totalRevenue = orders
          .filter(order => order.status === 'done')
          .reduce((sum, order) => sum + (order.profit || 0), 0);

        // Get unique customers
        const uniqueCustomers = new Set(orders.map(order => order.clientName)).size;

        const pendingOrders = orders.filter(order => order.status !== 'done').length;

        setStats({
          totalRevenue,
          totalOrders: orders.length,
          totalCustomers: uniqueCustomers,
          pendingOrders
        });

        // Set recent orders
        setRecentOrders(orders.slice(0, 5));

        // Simulate low stock items (replace with actual data)
        setLowStockItems([
          { id: 1, name: 'Product A', stock: 5, threshold: 10 },
          { id: 2, name: 'Product B', stock: 3, threshold: 8 },
          { id: 3, name: 'Product C', stock: 2, threshold: 7 }
        ]);

      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Container maxWidth="xl">
        {/* Header */}
        <Typography variant="h4" sx={{ mb: 4, fontWeight: 'bold' }}>
          Dashboard Overview
        </Typography>

        {/* Stats Cards */}
        <Grid container spacing={3} sx={{ mb: 4 }}>
          <Grid item xs={12} sm={6} md={3}>
            <Card raised>
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <AttachMoney color="primary" sx={{ fontSize: 40, mr: 1 }} />
                  <Typography color="textSecondary" variant="h6">
                    Total Revenue
                  </Typography>
                </Box>
                <Typography variant="h4">
                  {stats.totalRevenue.toFixed(2)} DT
                </Typography>
                <Typography variant="body2" sx={{ color: 'success.main', display: 'flex', alignItems: 'center' }}>
                  <ArrowUpward sx={{ mr: 1, fontSize: 'small' }} />
                  +15% from last month
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Card raised>
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <LocalShipping color="primary" sx={{ fontSize: 40, mr: 1 }} />
                  <Typography color="textSecondary" variant="h6">
                    Total Orders
                  </Typography>
                </Box>
                <Typography variant="h4">
                  {stats.totalOrders}
                </Typography>
                <Typography variant="body2" sx={{ color: 'success.main', display: 'flex', alignItems: 'center' }}>
                  <ArrowUpward sx={{ mr: 1, fontSize: 'small' }} />
                  +8% from last month
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Card raised>
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <People color="primary" sx={{ fontSize: 40, mr: 1 }} />
                  <Typography color="textSecondary" variant="h6">
                    Customers
                  </Typography>
                </Box>
                <Typography variant="h4">
                  {stats.totalCustomers}
                </Typography>
                <Typography variant="body2" sx={{ color: 'success.main', display: 'flex', alignItems: 'center' }}>
                  <ArrowUpward sx={{ mr: 1, fontSize: 'small' }} />
                  +12% from last month
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Card raised>
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Assessment color="primary" sx={{ fontSize: 40, mr: 1 }} />
                  <Typography color="textSecondary" variant="h6">
                    Pending Orders
                  </Typography>
                </Box>
                <Typography variant="h4">
                  {stats.pendingOrders}
                </Typography>
                <Typography variant="body2" sx={{ color: 'warning.main', display: 'flex', alignItems: 'center' }}>
                  <ArrowUpward sx={{ mr: 1, fontSize: 'small' }} />
                  Needs attention
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* Charts */}
        <Grid container spacing={3}>
          {/* Revenue Chart */}
          <Grid item xs={12} md={8}>
            <Paper sx={{ p: 3 }} elevation={3}>
              <Typography variant="h6" gutterBottom>
                Revenue Overview
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <AreaChart data={revenueData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Area 
                    type="monotone" 
                    dataKey="revenue" 
                    stroke="#8884d8" 
                    fill="#8884d8" 
                    fillOpacity={0.3} 
                  />
                </AreaChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>

          {/* Order Status Chart */}
          <Grid item xs={12} md={4}>
            <Paper sx={{ p: 3 }} elevation={3}>
              <Typography variant="h6" gutterBottom>
                Order Status
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={orderStatusData}
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                    label
                  >
                    {orderStatusData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>

          {/* Recent Orders */}
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 3 }} elevation={3}>
              <Typography variant="h6" gutterBottom>
                Recent Orders
              </Typography>
              <List>
                {recentOrders.map((order, index) => (
                  <React.Fragment key={order.id}>
                    <ListItem>
                      <ListItemIcon>
                        <Avatar sx={{ bgcolor: 'primary.main' }}>
                          {order.clientName[0]}
                        </Avatar>
                      </ListItemIcon>
                      <ListItemText
                        primary={order.clientName}
                        secondary={`Order #${order.trackingNumber} - ${order.totalAmount} DT`}
                      />
                      <Chip
                        label={order.status}
                        color={order.status === 'done' ? 'success' : 'warning'}
                        size="small"
                      />
                    </ListItem>
                    {index < recentOrders.length - 1 && <Divider />}
                  </React.Fragment>
                ))}
              </List>
            </Paper>
          </Grid>

          {/* Low Stock Alert / Order Stage Management */}
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 3 }} elevation={3}>
              <OrderStageManagement/>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default AdminDashboard;