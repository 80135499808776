import React, { useState, useEffect } from 'react';
import { db } from '../config/firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import { 
  Paper, 
  Box, 
  Typography, 
  Grid, 
  Card, 
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  CircularProgress,
  IconButton,
  Tooltip
} from '@mui/material';
import {
  AttachMoney,
  TrendingUp,
  CalendarToday,
  LocalShipping,
  Person
} from '@mui/icons-material';

const ViewOrders = () => {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalProfitMade, setTotalProfitMade] = useState(0);
  const [totalProfitToBeMade, setTotalProfitToBeMade] = useState(0);

  useEffect(() => {
    const fetchOrders = async () => {
      setIsLoading(true);
      try {
        const ordersCollection = collection(db, 'orders');
        const orderSnapshot = await getDocs(ordersCollection);
        const ordersList = orderSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));

        setOrders(ordersList);

        const profitMade = ordersList
          .filter(order => order.status === 'done')
          .reduce((sum, order) => sum + (order.profit || 0), 0);

        const profitToBeMade = ordersList
          .filter(order => order.status !== 'done')
          .reduce((sum, order) => sum + (order.profit || 0), 0);

        setTotalProfitMade(profitMade);
        setTotalProfitToBeMade(profitToBeMade);
      } catch (error) {
        console.error('Error fetching orders: ', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const formatCurrency = (amount) => {
    return `${amount.toFixed(2)} DT`;
  };

  const getStatusColor = (status) => {
    return status === 'done' ? 'success' : 'primary';
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3, maxWidth: 1400, margin: '0 auto' }}>
      <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold' }}>
        Orders Overview
      </Typography>

      <Grid container spacing={3} sx={{ mb: 4 }}>
        {/* Completed Orders Profit Card */}
        <Grid item xs={12} md={6}>
          <Card raised>
            <CardContent>
              <Box display="flex" alignItems="center" mb={2}>
                <AttachMoney color="success" sx={{ fontSize: 40, mr: 1 }} />
                <Typography variant="h6" color="textSecondary">
                  Total Profit (Completed)
                </Typography>
              </Box>
              <Typography variant="h4" color="success.main">
                {formatCurrency(totalProfitMade)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Ongoing Orders Profit Card */}
        <Grid item xs={12} md={6}>
          <Card raised>
            <CardContent>
              <Box display="flex" alignItems="center" mb={2}>
                <TrendingUp color="primary" sx={{ fontSize: 40, mr: 1 }} />
                <Typography variant="h6" color="textSecondary">
                  Potential Profit (Ongoing)
                </Typography>
              </Box>
              <Typography variant="h4" color="primary.main">
                {formatCurrency(totalProfitToBeMade)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <TableContainer component={Paper} elevation={3}>
        <Table sx={{ minWidth: 800 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Order Details</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Client Info</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Dates</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Financial Details</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Purchase Info</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order) => (
              <TableRow 
                key={order.id}
                sx={{ '&:hover': { backgroundColor: 'action.hover' } }}
              >
                <TableCell>
                  <Box>
                    <Typography variant="subtitle2" gutterBottom>
                      {order.trackingNumber}
                    </Typography>
                    <Chip
                      label={order.status}
                      color={getStatusColor(order.status)}
                      size="small"
                    />
                  </Box>
                </TableCell>

                <TableCell>
                  <Box display="flex" alignItems="center">
                    <Person sx={{ mr: 1, color: 'action.active' }} />
                    <Box>
                      <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                        {order.clientName}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {order.clientPhoneNumber}
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>

                <TableCell>
                  <Box>
                    <Box display="flex" alignItems="center" mb={1}>
                      <CalendarToday sx={{ fontSize: 'small', mr: 1, color: 'action.active' }} />
                      <Typography variant="body2">
                        Order: {formatDate(order.orderDate)}
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <LocalShipping sx={{ fontSize: 'small', mr: 1, color: 'action.active' }} />
                      <Typography variant="body2">
                        Delivery: {formatDate(order.expectedDeliveryDate)}
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>

                <TableCell>
                  <Box>
                    <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                      Total: {formatCurrency(order.totalAmount)}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Paid: {formatCurrency(order.firstPaycheck)}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Balance: {formatCurrency(order.remainingBalance)}
                    </Typography>
                    <Typography variant="body2" color="success.main" sx={{ fontWeight: 'medium' }}>
                      Profit: {formatCurrency(order.profit)}
                    </Typography>
                  </Box>
                </TableCell>

                <TableCell>
                  <Box>
                    <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                      {order.purchaseSite}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Items: {order.purchasedItemsIds.length}
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ViewOrders;