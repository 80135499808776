import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import './App.css';
import Home from './Home';
import AddProduct from './admin/AddProduct';
import PackageTracker from './components/PackageTracker';
import AddOrderTracking from './admin/orders/AddOrderTracking';
import AddOrder from './admin/orders/AddOrder';
import AdminLogin from './admin/AdminLogin';
import { useEffect } from 'react';
import DressMe from './components/DressMe';
import VisitTracker from './SiteTracker';
import ViewOrders from './admin/ViewOrders';
import AdminDashboard from './admin/AdminDash';
import ProtectedRoute from './ProtectedRoute';
import TermsOfService from './TermsOfService';
const App = ({ logPageView }) => {
  useEffect(() => {
    logPageView();
  }, [logPageView]);

  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <main className="flex-grow">
          <Routes>
            {/* Public routes */}
            <Route path="/" element={<Home />} />
            <Route path="/track" element={<PackageTracker />} />
            <Route path="/dressme" element={<DressMe />} />
            <Route path="/tos" element={<TermsOfService />} />
            <Route path="/admin/login" element={<AdminLogin />} />

            {/* Protected admin routes */}
            <Route path="/admin/*" element={
              <ProtectedRoute>
                <Routes>
                  <Route path="dashboard" element={<AdminDashboard />} />
                  <Route path="add-product" element={<AddProduct />} />
                  <Route path="add-tracking" element={<AddOrderTracking />} />
                  <Route path="add-order" element={<AddOrder />} />
                  <Route path="view-orders" element={<ViewOrders />} />
                </Routes>
              </ProtectedRoute>
            } />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;