import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Typography,
  useTheme
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const TermsOfService = () => {
  const theme = useTheme();

  const terms = [
    {
      title: "1. Our Product Sourcing",
      content: [
        "At Moonlit Bloom, we carefully curate our collection from trusted international platforms:",
        "  - SHEIN: For trendy fashion pieces",
        "  - Temu: Offering diverse style options",
        "  - AliExpress: Unique and quality selections",
        "  - Amazon: Premium international brands",
        "We work hard to maintain timely deliveries, though shipping times may vary based on our suppliers.",
        "Our team personally ensures strong relationships with reliable suppliers to bring you the best quality products."
      ]
    },
    {
      title: "2. Our Reservation Policy",
      content: [
        "When You Choose Moonlit Bloom:",
        "• We provide a 24-hour window for you to make changes or cancel your reservation",
        "• After this period, your reservation is confirmed and final",
        "",
        "Our Non-refundable Reservation Policy:",
        "• We cannot process exchanges, cancellations, or returns after the 24-hour window",
        "• This applies to all situations including:",
        "  - Changes in preference",
        "  - Order modifications",
        "  - Size adjustments"
      ]
    },
    {
      title: "3. Our Shipping Promise",
      content: [
        "Our Nationwide Service:",
        "• We're proud to deliver to every corner of Tunisia",
        "",
        "What to Expect:",
        "• We typically deliver within 10-25 business days",
        "• Our delivery times vary based on the product's origin",
        "• We handle international shipping with care",
        "",
        "Keeping You Informed:",
        "• We'll provide your tracking information as soon as we dispatch",
        "• Our team sends regular shipping updates",
        "• We're here to answer any shipping questions"
      ]
    },
    {
      title: "4. Import & Customs Information",
      content: [
        "Your Responsibilities:",
        "• We want to be transparent that customers handle:",
        "  - Customs fees",
        "  - Import duties",
        "  - Local taxes",
        "",
        "Additional Details:",
        "• These fees vary by your region",
        "• Local authorities set these charges",
        "• While we can't control customs timing, we're here to help navigate the process"
      ]
    },
    {
      title: "5. Our Deposit System",
      content: [
        "When We Request Deposits:",
        "• We may ask for a deposit on our premium items",
        "• We ensure your deposit is refundable if:",
        "  - We discover product defects",
        "  - Moonlit Bloom is at fault",
        "",
        "How We Protect Your Deposit:",
        "• We maintain secure handling",
        "• Our documentation is always clear",
        "• We ensure transparent refund processes"
      ]
    },
    {
      title: "6. Our Returns & Exchanges",
      content: [
        "If You Receive a Defective Item:",
        "• We ask that you contact us within 48 hours",
        "• We'll need you to provide:",
        "  - Clear photos of any defects",
        "  - Your order number",
        "  - A description of the issue",
        "",
        "Items We Cannot Accept Returns For:",
        "• We maintain strict hygiene standards for:",
        "  - Lingerie",
        "  - Intimate wear",
        "  - Sale items",
        "• We do make exceptions for manufacturing defects"
      ]
    },
    {
      title: "7. Our Pricing & Payment",
      content: [
        "Our Pricing Promise:",
        "• We include VAT in all our prices",
        "• We display prices in local currency",
        "",
        "Ways to Pay With Us:",
        "• We accept:",
        "  - LaPoste",
        "  - Credit Card",
        "  - PayPal",
        "  - Cash on Delivery",
        "  - Bank Transfer",
        "",
        "Custom Order Deposits:",
        "• We require 50% upfront for custom pieces",
        "• We'll clearly explain our refund terms"
      ]
    },
    {
      title: "8. Our Cancellation Terms",
      content: [
        "Your Cancellation Window:",
        "• We provide 24 hours to cancel your order",
        "• We cannot make exceptions after this time",
        "",
        "How to Cancel With Us:",
        "• We ask that you:",
        "  - Use our official cancellation channels",
        "  - Include your order number",
        "  - Let us know your reason"
      ]
    },
    {
      title: "9. Our Privacy Commitment",
      content: [
        "How We Protect Your Data:",
        "• We ensure secure storage of your information",
        "• We only use your data for:",
        "  - Processing your orders",
        "  - Improving our service to you",
        "  - Supporting your shopping experience",
        "",
        "Our Data Sharing Policy:",
        "• We never share your information without permission",
        "• We only share when legally required"
      ]
    },
    {
      title: "10. Updates to Our Terms",
      content: [
        "When We Update Our Policies:",
        "• We reserve the right to modify these terms",
        "• We'll notify you through:",
        "  - Our website",
        "  - Email updates",
        "",
        "Your Continued Trust:",
        "• We consider your continued use as acceptance",
        "• We encourage you to review our terms regularly"
      ]
    }
  ];

  return (
    <Container maxWidth="lg" sx={{ my: 4 }}>
      <Card 
        elevation={3}
        sx={{
          backgroundColor: theme.palette.background.paper,
          borderRadius: 2
        }}
      >
        <CardHeader
          title={
            <Typography 
              variant="h4" 
              component="h1" 
              align="center"
              sx={{ 
                color: theme.palette.primary.main,
                fontWeight: 600,
                py: 2
              }}
            >
              Welcome to Moonlit Bloom's Terms of Service
            </Typography>
          }
          sx={{
            backgroundColor: theme.palette.grey[50],
            borderBottom: `1px solid ${theme.palette.divider}`
          }}
        />
        <CardContent sx={{ p: 4 }}>
          <Typography variant="subtitle1" sx={{ mb: 4, textAlign: 'center', color: theme.palette.text.secondary }}>
            We're committed to providing you with the best shopping experience. Here's everything you need to know about shopping with us.
          </Typography>
          <Box sx={{ width: '100%' }}>
            {terms.map((section, index) => (
              <Accordion
                key={index}
                sx={{
                  '&:before': {
                    display: 'none',
                  },
                  mb: 1,
                  boxShadow: 'none',
                  border: `1px solid ${theme.palette.divider}`,
                  borderRadius: '4px !important',
                  '&:not(:last-child)': {
                    borderBottom: 0,
                  },
                  '&:first-of-type': {
                    borderRadius: '4px !important',
                  },
                  '&:last-of-type': {
                    borderRadius: '4px !important',
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{
                    backgroundColor: theme.palette.grey[50],
                    '&:hover': {
                      backgroundColor: theme.palette.grey[100],
                    },
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 500,
                      color: theme.palette.text.primary,
                    }}
                  >
                    {section.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 3 }}>
                  {section.content.map((line, lineIndex) => (
                    <Typography
                      key={lineIndex}
                      variant="body1"
                      component="p"
                      sx={{
                        whiteSpace: 'pre-wrap',
                        mb: 1,
                        color: theme.palette.text.secondary,
                        lineHeight: 1.7,
                      }}
                    >
                      {line}
                    </Typography>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default TermsOfService;