import React, { useState, useEffect } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Typography, 
  Button, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Select, 
  MenuItem, 
  FormControl, 
  InputLabel,
  Box,
  CircularProgress,
  Chip
} from '@mui/material';
import { 
  collection, 
  query, 
  getDocs, 
  updateDoc, 
  doc, 
  orderBy 
} from 'firebase/firestore';
import { db } from '../../config/firebaseConfig';

// Order stages with colors and labels
const ORDER_STAGES = [
  { 
    value: 1, 
    label: 'في طريق الشحن إلى ألمانيا', 
    color: 'default' 
  },
  { 
    value: 2, 
    label: 'في ألمانيا', 
    color: 'primary' 
  },
  { 
    value: 3, 
    label: 'في طريق الشحن إلى تونس', 
    color: 'secondary' 
  },
  { 
    value: 4, 
    label: 'في تونس', 
    color: 'warning' 
  },
  { 
    value: 5, 
    label: 'تم التسليم', 
    color: 'success' 
  }
];

const OrderStageManagement = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [newStage, setNewStage] = useState(1);

  // Fetch orders
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const q = query(
          collection(db, 'trackingOrders'), 
          orderBy('orderDate', 'desc')
        );
        const querySnapshot = await getDocs(q);
        
        const fetchedOrders = querySnapshot.docs.map(docSnap => ({
          id: docSnap.id,
          ...docSnap.data()
        }));

        setOrders(fetchedOrders);
      } catch (error) {
        console.error('Error fetching orders:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  // Open stage change dialog
  const handleOpenStageDialog = (order) => {
    setSelectedOrder(order);
    setNewStage(order.statusUpdates?.[0]?.currentStage || 1);
    setOpenDialog(true);
  };

  // Update order stage
  const handleUpdateStage = async () => {
    if (!selectedOrder) return;

    try {
      const orderRef = doc(db, 'trackingOrders', selectedOrder.id);
      await updateDoc(orderRef, {
        statusUpdates: [
          { 
            date: new Date().toISOString(), 
            currentStage: parseInt(newStage) 
          }
        ]
      });

      // Update local state
      setOrders(prevOrders => 
        prevOrders.map(order => 
          order.id === selectedOrder.id 
            ? {
                ...order, 
                statusUpdates: [{ 
                  date: new Date().toISOString(), 
                  currentStage: parseInt(newStage) 
                }]
              }
            : order
        )
      );

      setOpenDialog(false);
    } catch (error) {
      console.error('Error updating order stage:', error);
    }
  };

  // Render stage chip
  const renderStageChip = (stage) => {
    const stageInfo = ORDER_STAGES.find(s => s.value === stage);
    return (
      <Chip 
        label={stageInfo?.label || 'غير محدد'}
        color={stageInfo?.color || 'default'}
        size="small"
      />
    );
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Typography variant="h4" gutterBottom align="center" sx={{ my: 3 }}>
        إدارة حالات الطلبات
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>رقم التتبع</TableCell>
              <TableCell>تاريخ الطلب</TableCell>
              <TableCell>تاريخ التسليم المتوقع</TableCell>
              <TableCell>الحالة الحالية</TableCell>
              <TableCell>الإجراءات</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order) => {
              const currentStage = order.statusUpdates?.[0]?.currentStage || 1;
              
              return (
                <TableRow key={order.id}>
                  <TableCell>{order.trackingNumber}</TableCell>
                  <TableCell>{order.orderDate}</TableCell>
                  <TableCell>{order.expectedDeliveryDate}</TableCell>
                  <TableCell>
                    {renderStageChip(currentStage)}
                  </TableCell>
                  <TableCell>
                    <Button 
                      variant="outlined" 
                      color="primary"
                      onClick={() => handleOpenStageDialog(order)}
                    >
                      تغيير الحالة
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Stage Change Dialog */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>تغيير حالة الطلب</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>اختر الحالة الجديدة</InputLabel>
            <Select
              value={newStage}
              label="اختر الحالة الجديدة"
              onChange={(e) => setNewStage(e.target.value)}
            >
              {ORDER_STAGES.map((stage) => (
                <MenuItem key={stage.value} value={stage.value}>
                  {stage.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="secondary">
            إلغاء
          </Button>
          <Button onClick={handleUpdateStage} color="primary">
            تأكيد التغيير
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OrderStageManagement;